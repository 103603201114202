import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { AlignStyle, NumberControlDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { takeUntil, tap } from 'rxjs/operators';
import { FormControlNotificationTemplates, IControlContext } from '../../../engine-sdk';
import { NumberControlComponent } from '../../../shared/reactive-controls/components/number/number-control.component';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-number-control[engineNumberFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineNumberFormControlDirective }],
})
export class EngineNumberFormControlDirective extends EngineFormControlDirective {
  protected get _numberBaseControl(): NumberControlComponent {
    return this._baseControl as NumberControlComponent;
  }

  @Input() set engineNumberControlDefinition(definition: NumberControlDto) {
    this.minValue = definition.minValue;
    this.maxValue = definition.maxValue;
    this.engineControlDefinition = definition;
    this.textAlignStyle = definition.textAlign;
  }

  @Input() set minValue(minValue: number) {
    this._numberBaseControl.minValue = minValue;
  }

  @Input() set maxValue(maxValue: number) {
    this._numberBaseControl.maxValue = maxValue;
  }

  @Input() set textAlignStyle(textAlign: AlignStyle) {
    this._numberBaseControl.textAlignStyle = this.getTextAlignStyle(textAlign);
  }

  get textAlignStyle(): any {
    return this._numberBaseControl.textAlignStyle;
  }

  get minValue(): number {
    return this._numberBaseControl.minValue;
  }

  get maxValue(): number {
    return this._numberBaseControl.maxValue;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);

    this._baseControl
      .controlValueChanges()
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.refreshNumberNotification(false)),
      )
      .subscribe();

    this._baseControl
      .focusout()
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.refreshNumberNotification(true)),
      )
      .subscribe();
  }

  private refreshNumberNotification(onFocusOut: boolean) {
    this.refreshControlMinValueNotification(this, onFocusOut);
    this.refreshControlMaxValueNotification(this, onFocusOut);
    this.refreshControlRangeValueNotification(this, onFocusOut);
  }

  private refreshControlMinValueNotification(control: IControlContext, onFocusOut: boolean) {
    if (!control || control.isReadOnly || !control.isVisible) return;
    if (control.hasError('min') && !this._baseControl.hasValidator('max') && this._baseControl.formControl.touched) {
      if (onFocusOut) {
        this._formNotificationService.addNotification(
          FormControlNotificationTemplates.MinValueValidation,
          control,
          this.minValue,
        );
      }
    } else {
      this._formNotificationService.removeNotification(
        FormControlNotificationTemplates.MinValueValidation,
        control,
        this.minValue,
      );
    }
  }

  private refreshControlMaxValueNotification(control: IControlContext, onFocusOut: boolean) {
    if (!control || control.isReadOnly || !control.isVisible) return;
    if (control.hasError('max') && !this._baseControl.hasValidator('min') && this._baseControl.formControl.touched) {
      if (onFocusOut) {
        this._formNotificationService.addNotification(
          FormControlNotificationTemplates.MaxValueValidation,
          control,
          this.maxValue,
        );
      }
    } else {
      this._formNotificationService.removeNotification(
        FormControlNotificationTemplates.MaxValueValidation,
        control,
        this.maxValue,
      );
    }
  }

  private refreshControlRangeValueNotification(control: IControlContext, onFocusOut: boolean) {
    if (!control || control.isReadOnly || !control.isVisible) return;
    if (
      (control.hasError('max') || control.hasError('min')) &&
      this._baseControl.hasValidator('min') &&
      this._baseControl.hasValidator('max') &&
      this._baseControl.formControl.touched
    ) {
      if (onFocusOut) {
        this._formNotificationService.addNotification(
          FormControlNotificationTemplates.RangeValueValidation,
          control,
          `${this.minValue} - ${this.maxValue}`,
        );
      }
    } else {
      this._formNotificationService.removeNotification(
        FormControlNotificationTemplates.RangeValueValidation,
        control,
        `${this.minValue} - ${this.maxValue}`,
      );
    }
  }
}
