<div *ngIf="formControl">
  <mat-form-field class="control-input" [ngStyle]="textAlignStyle">
    <input
      [formControl]="formControl"
      type="number"
      (blur)="onBlur()"
      matInput
      [id]="id"
      [name]="name"
      [placeholder]="label" />
  </mat-form-field>
  <app-control-error [control]="formControl" [offset]="-16"></app-control-error>
</div>
