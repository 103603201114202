<div *ngIf="formControl">
  <mat-form-field class="control-input" [ngStyle]="textAlignStyle">
    <textarea
      [formControl]="formControl"
      (blur)="onBlur()"
      matInput
      [id]="id"
      [name]="name"
      [placeholder]="label"
      cdkTextareaAutosize
      [cdkAutosizeMinRows]="minRowsNumber"
      [cdkAutosizeMaxRows]="maxRowsNumber"
      #autosize="cdkTextareaAutosize"></textarea>
  </mat-form-field>
  <app-control-error [control]="formControl" [offset]="-16"></app-control-error>
</div>
