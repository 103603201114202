import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GridItem } from '@progress/kendo-angular-grid';
import { GridColumn } from '../../models/grid-definition.model';
import { CellClickEvent } from '../../models/grid-events.model';

@Component({
  selector: 'app-grid-column-cell',
  templateUrl: './grid-column-cell.component.html',
  styleUrls: ['./grid-column-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridColumnCellComponent {
  @Input() column: GridColumn;
  @Input() dataItem: GridItem;

  @Output() cellClick = new EventEmitter<CellClickEvent>();

  emitCellClick(event: Event, fieldName?: string) {
    if (this.column.isClickable) {
      event.stopImmediatePropagation();
      this.cellClick.emit({
        column: this.column,
        dataItem: this.dataItem,
        fieldName: fieldName ?? this.column.primaryField,
      });
    }
  }
}
