import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { AlignStyle, TextControlDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { takeUntil, tap } from 'rxjs/operators';
import { FormControlNotificationTemplates, IControlContext } from '../../../engine-sdk';
import { TextControlComponent } from '../../../shared/reactive-controls/components/text/text-control.component';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-text-control[engineTextFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineTextFormControlDirective }],
})
export class EngineTextFormControlDirective extends EngineFormControlDirective {
  protected get _textBaseControl(): TextControlComponent {
    return this._baseControl as TextControlComponent;
  }

  @Input() set engineTextControlDefinition(definition: TextControlDto) {
    this.minLength = definition.minLength;
    this.maxLength = definition.maxLength;
    this.engineControlDefinition = definition;
    this.textAlignStyle = definition.textAlign;
  }

  @Input() set minLength(minLength: number) {
    this._textBaseControl.minLength = minLength;
  }

  @Input() set maxLength(maxLength: number) {
    this._textBaseControl.maxLength = maxLength;
  }

  @Input() set textAlignStyle(textAlign: AlignStyle) {
    this._textBaseControl.textAlignStyle = this.getTextAlignStyle(textAlign);
  }

  get minLength(): number {
    return this._textBaseControl.minLength;
  }

  get maxLength(): number {
    return this._textBaseControl.maxLength;
  }

  get textAlignStyle(): any {
    return this._textBaseControl.textAlignStyle;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);

    this._baseControl
      .controlValueChanges()
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.refreshTextNotification(false)),
      )
      .subscribe();

    this._baseControl
      .focusout()
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.refreshTextNotification(true)),
      )
      .subscribe();
  }

  private refreshTextNotification(onFocusOut: boolean) {
    this.refreshControlMinLengthNotification(this, onFocusOut);
    this.refreshControlMaxLengthNotification(this, onFocusOut);
    this.refreshControlRangeLengthNotification(this, onFocusOut);
  }

  private refreshControlMinLengthNotification(control: IControlContext, onFocusOut: boolean) {
    if (!control || control.isReadOnly || !control.isVisible) return;
    if (
      control.hasError('minlength') &&
      !this._baseControl.hasValidator('maxLength') &&
      this._baseControl.formControl.touched
    ) {
      if (onFocusOut) {
        this._formNotificationService.addNotification(
          FormControlNotificationTemplates.MinLengthValidation,
          control,
          this.minLength,
        );
      }
    } else {
      this._formNotificationService.removeNotification(
        FormControlNotificationTemplates.MinLengthValidation,
        control,
        this.minLength,
      );
    }
  }

  private refreshControlMaxLengthNotification(control: IControlContext, onFocusOut: boolean) {
    if (!control || control.isReadOnly || !control.isVisible) return;
    if (
      control.hasError('maxlength') &&
      !this._baseControl.hasValidator('minLength') &&
      this._baseControl.formControl.touched
    ) {
      if (onFocusOut) {
        this._formNotificationService.addNotification(
          FormControlNotificationTemplates.MaxLengthValidation,
          control,
          this.maxLength,
        );
      }
    } else {
      this._formNotificationService.removeNotification(
        FormControlNotificationTemplates.MaxLengthValidation,
        control,
        this.maxLength,
      );
    }
  }

  private refreshControlRangeLengthNotification(control: IControlContext, onFocusOut: boolean) {
    if (!control || control.isReadOnly || !control.isVisible) return;
    if (
      (control.hasError('maxlength') || control.hasError('minlength')) &&
      this._baseControl.hasValidator('minLength') &&
      this._baseControl.hasValidator('maxLength') &&
      this._baseControl.formControl.touched
    ) {
      if (onFocusOut) {
        this._formNotificationService.addNotification(
          FormControlNotificationTemplates.RangeLengthValidation,
          control,
          `${this.minLength} - ${this.maxLength}`,
        );
      }
    } else {
      this._formNotificationService.removeNotification(
        FormControlNotificationTemplates.RangeLengthValidation,
        control,
        `${this.minLength} - ${this.maxLength}`,
      );
    }
  }
}
