import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProcessFeatureState } from './index';
import { FEATURE_NAME, IProcessState } from './state';
import { ProcessInstanceHistoryDto } from '@core/services/api-clients';

export const processFeatureSelector = createFeatureSelector<ProcessFeatureState>(FEATURE_NAME);
export const processSelector = createSelector(processFeatureSelector, (state: ProcessFeatureState) => state.process);

export const getProcessAssignments = createSelector(
  processSelector,
  (state: IProcessState) => state.processEntityAssignments,
);
export const getProcessAssignmentsForEntity = (entityId: string) =>
  createSelector(getProcessAssignments, (processAssignments) =>
    processAssignments[entityId] ? processAssignments[entityId] : [],
  );

export const getProcessStates = createSelector(processSelector, (state: IProcessState) => state.processStates);
export const getProcessState = (processStateId: string) =>
  createSelector(getProcessStates, (processStates) => {
    let result = processStates[processStateId] ? processStates[processStateId] : null;
    return result;
  });

export const getProcessHistory = createSelector(processSelector, (state: IProcessState) => state.processHistory);
export const getProcessHistoryForRecord = (processAssignmentId: string, recordId: string, currentStateId: string) =>
  createSelector(getProcessHistory, (processesHistory) => {
    let processAssignmentHistory: ProcessInstanceHistoryDto[][] = processesHistory[processAssignmentId]
      ? processesHistory[processAssignmentId]
      : null;
    if (processAssignmentHistory) {
      let recordProcesshistory = processAssignmentHistory.find((x) =>
        x.some((historyItem) => historyItem.recordId == recordId),
      );
      return recordProcesshistory?.filter((x) => !!x.processState && x.processState?.id != currentStateId);
    }
    return null;
  });

export const getProcessStateHistoryItem = (processAssignmentId: string, recordId: string, stateId: string) =>
  createSelector(getProcessHistory, (processesHistory) => {
    let processAssignmentHistory: ProcessInstanceHistoryDto[][] = processesHistory[processAssignmentId]
      ? processesHistory[processAssignmentId]
      : null;
    if (processAssignmentHistory) {
      let recordProcesshistory = processAssignmentHistory.find((x) =>
        x.some((historyItem) => historyItem.recordId == recordId),
      );
      return recordProcesshistory?.find((x) => !!x.processState && x.processState?.id == stateId);
    }
    return null;
  });
