import { MsalGuardConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { environment } from '@env';

const msalGuardConfigFactory = (): MsalGuardConfiguration => {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.msalModule.appScope],
    },
  };
};

export const authGuardConfigProvider = {
  provide: MSAL_GUARD_CONFIG,
  useFactory: msalGuardConfigFactory,
};
