import { ChangeDetectorRef, Directive, Inject, Injector, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RecordsSelectorViewConfig } from '@core/engine-views/components/records-selector-view/records-selector-view-config';
import { RecordsSelectorViewComponent } from '@core/engine-views/components/records-selector-view/records-selector-view.component';
import { NavigationService } from '@core/navigation/services/navigation.service';
import { AlignStyle, EntityDto, LookupControlDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { AutocompleteControlComponent } from '@shared/reactive-controls/components/autocomplete/autocomplete-control.component';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ILookupControlContext } from '../../../engine-sdk';
import { EngineLookupService } from '../services/engine-lookup.service';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-autocomplete-control[engineLookupFormControl]',
  providers: [
    EngineLookupService,
    { provide: EngineFormControlDirective, useExisting: EngineLookupFormControlDirective },
  ],
})
export class EngineLookupFormControlDirective
  extends EngineFormControlDirective
  implements ILookupControlContext, OnInit {
  private _targetEntity: EntityDto;
  private _formId: string;
  private _expanderViewId: string;
  private _isCreateButtonVisible: boolean;
  private _isOpenButtonVisible: boolean;
  private _showField: string;
  protected get _lookupBaseControl(): AutocompleteControlComponent {
    return this._baseControl as AutocompleteControlComponent;
  }

  @Input() set engineLookupControlDefinition(definition: LookupControlDto) {
    this.targetEntity = definition.targetEntity;
    this.showField = definition.showField;
    this.formId = definition.formId;
    this.expanderViewId = definition.expanderViewId;
    this.isCreateButtonVisible = definition.isCreateButtonVisible;
    this.isOpenButtonVisible = definition.isOpenButtonVisible;
    this.engineControlDefinition = definition;
    this.textAlignStyle = definition.textAlign;
  }
  @Input() set targetEntity(v: EntityDto) {
    this._targetEntity = v;
    this.refreshActionButtonsVisibility();
  }
  @Input() set showField(v: string) {
    this._showField = v;
  }
  @Input() set formId(v: string) {
    this._formId = v;
  }
  @Input() set isCreateButtonVisible(v: boolean) {
    this._isCreateButtonVisible = v;
    this.refreshActionButtonsVisibility();
  }
  @Input() set isOpenButtonVisible(v: boolean) {
    this._isOpenButtonVisible = v;
    this.refreshActionButtonsVisibility();
  }

  @Input() set textAlignStyle(textAlign: AlignStyle) {
    this._lookupBaseControl.textAlignStyle = this.getTextAlignStyle(textAlign);
  }

  get textAlignStyle(): any {
    return this._lookupBaseControl.textAlignStyle;
  }

  override get isReadOnly(): boolean {
    return super.isReadOnly;
  }
  @Input() override set isReadOnly(v: boolean) {
    super.isReadOnly = v;
    this.refreshActionButtonsVisibility();
  }
  set expanderViewId(v: string) {
    this._expanderViewId = v;

    if (!!this._expanderViewId) {
      this._lookupBaseControl.actionOptions = [
        {
          id: 'expand',
          label: 'EXPAND',
          handler: this.onExpandPressed.bind(this),
        },
      ];
    }
  }
  set value(v: any) {
    super.value = v;
  }

  get formId(): string {
    return this._formId;
  }
  get expanderViewId(): string {
    return this._expanderViewId;
  }
  get isCreateButtonVisible(): boolean {
    return this._isCreateButtonVisible;
  }
  get isOpenButtonVisible(): boolean {
    return this._isOpenButtonVisible;
  }
  get targetEntity(): EntityDto {
    return this._targetEntity;
  }
  get showField(): string {
    return this._showField;
  }
  get value(): any {
    return super.value;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
    private _lookupService: EngineLookupService,
    private _navigationContext: NavigationService,
    private _matDialog: MatDialog,
    private _changes: ChangeDetectorRef,
  ) {
    super(parentWidget, scriptRunnerService, injector);

    this._form.isWidgetLoaded$
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this._lookupService.init(this._targetEntity, this._showField, this.value)),
      )
      .subscribe();

    this._baseControl
      .controlValueChanges()
      .pipe(
        takeUntil(this._destroy$),
        map((e) => e.newValue),
        tap((id) => {
          if (id) {
            this._lookupService.selectOption(id);
          } else {
            this._lookupService.clearOptionSelection();
            this._lookupService.search('');
          }
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this._lookupService.options$
      .pipe(
        takeUntil(this._destroy$),
        tap((options) => {
          this._lookupBaseControl.options = options;
          this._changes.markForCheck();
        }),
      )
      .subscribe();

    this._lookupBaseControl.addClick
      .asObservable()
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.onAddClick()),
      )
      .subscribe();

    this._lookupBaseControl.editClick
      .asObservable()
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.onEditClick()),
      )
      .subscribe();

    this._lookupBaseControl.filterChange
      .asObservable()
      .pipe(
        takeUntil(this._destroy$),
        tap((filterText) => {
          this._lookupService.clearOptionSelection();
          this._lookupService.search(filterText);
        }),
      )
      .subscribe();

    this._lookupService.preFetch$
      .pipe(
        takeUntil(this._destroy$),
        tap((preFetchExecutionEvent) => {
          this._events$.next(preFetchExecutionEvent);
          this.triggerEvent(
            this.createEventArgs(preFetchExecutionEvent, preFetchExecutionEvent.fetchAsync.bind(preFetchExecutionEvent)),
          );
        }),
      )
      .subscribe();
  }

  addFilter(filter: CompositeFilterDescriptor): void {
    this._lookupService.addFilter(filter);
  }

  setFilter(filter: CompositeFilterDescriptor): void {
    this._lookupService.setFilter(filter);
  }

  setCustomFilter(filter: string): void {
    this._lookupService.setCustomFilter(filter);
  }

  private onExpandPressed(): void {
    this._matDialog.open(RecordsSelectorViewComponent, {
      width: '70vw',
      panelClass: 'app-expander-dialog',
      autoFocus: false,
      restoreFocus: false,
      data: {
        entityName: this.targetEntity.name,
        viewId: this.expanderViewId,
        selectedIds: this.value ? [this.value] : null,
        onConfirm: (entityIds: string[]) => {
          this.setDirty(true);
          this.value = entityIds[0];
        },
        selectionMode: 'single',
        query: this._lookupService.odataQuery,
      } as RecordsSelectorViewConfig,
    });
  }

  private onEditClick() {
    if (this.targetEntity.id && this.formId && this.value) {
      this._navigationContext.navigateToForm(this.targetEntity.id, this.formId, this.value, {
        popCount: 0,
      });
    }
  }

  private onAddClick() {
    if (this.targetEntity.id && this.formId) {
      this._navigationContext.navigateToCreateForm(this.targetEntity.id, this.formId, {
        popCount: 0,
        queryParams: {
          args: JSON.stringify({ $mappings: { [this.primaryAttribute]: 'Id' } }),
        },
      });
    }
  }

  private refreshActionButtonsVisibility() {
    this._lookupBaseControl.setOpenButtonVisibility(
      this._isOpenButtonVisible && this.targetEntity.canRead && !this.targetEntity.canUpdate && this.formId != null,
    );
    this._lookupBaseControl.setUpdateButtonVisibility(
      this._isOpenButtonVisible && this.targetEntity.canUpdate && this.formId != null,
    );
    this._lookupBaseControl.setCreateButtonVisibility(
      this._isCreateButtonVisible &&
      this.targetEntity.canCreate &&
      this.formId != null &&
      this._baseControl.formControl.enabled,
    );
  }
}
