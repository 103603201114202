import { toODataString } from '@progress/kendo-data-query';
import { IEngineODataState } from '../../../engine-sdk/contract/odata/iodata-state.model';

export class EngineODataStateParser {
  static toODataString(state: IEngineODataState, utcDates: boolean = false) {
    if (state) {
      let odataString = toODataString(state, { utcDates });
      if (!state.customFilter) return odataString;

      const filterPartStartIndex = odataString.indexOf('$filter=');
      if (filterPartStartIndex == -1) return `${odataString}&$filter=${state.customFilter}`;

      const filterPartEndIndex = odataString.indexOf('&', filterPartStartIndex);
      const filterPart = odataString.substring(
        filterPartStartIndex,
        filterPartEndIndex != -1 ? filterPartEndIndex : undefined,
      );

      odataString = odataString.replace(
        filterPart,
        `$filter=(${state.customFilter}) and (${filterPart.substring(filterPart.indexOf('=') + 1)})`,
      );
      return odataString;
    }

    return '';
  }
}
