import { inject, Inject, Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { EMPTY, from, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthenticationSuccess } from '../store/actions';
import { IAuthService } from '../models/iauth-service.model';
import { AuthSelectors } from '../store';
import { AuthenticationType } from '../models/authentication-type.enum';
import { environment } from '@env';
import { MomentService } from '../../../shared/moment/services/moment.service';

@Injectable()
export abstract class MsalAuthBaseService implements IAuthService, OnDestroy {
  private _momentService = inject(MomentService);

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _isInitialized: boolean = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private _msalGuardConfig: MsalGuardConfiguration,
    private _broadcastService: MsalBroadcastService,
    private _msalService: MsalService,
    private _store: Store,
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  init(): void {
    if (this._isInitialized) return;
    this._isInitialized = true;
    this.startListeningOnAuthStateChange();
  }

  getToken(): Observable<AuthenticationResult> {
    const account = this.getCurrentAccount();
    if (account == null) {
      return EMPTY;
    }

    return from(
      this._msalService.instance.acquireTokenSilent({
        account: account,
        scopes: (this._msalGuardConfig.authRequest as RedirectRequest).scopes,
      }),
    );
  }

  isUserAuthenticated(): Observable<boolean> {
    return this._store.select(AuthSelectors.isAuthenticated);
  }

  logout(): Observable<any>  {
    return this._msalService.logoutRedirect();
  }

  getCurrentAccount(): AccountInfo {
    const accounts = this._msalService.instance
      .getAllAccounts()
      .filter(x => x.idTokenClaims.aud == (environment.msalModule.clientID))
      .sort((a, b) => (this.getTokenExpDate(a) > this.getTokenExpDate(b) ? 0 : 1));
    if (accounts.length > 0) {
      this._msalService.instance.setActiveAccount(accounts[0]);
    }
    const activeAccount = this._msalService.instance.getActiveAccount();
    return activeAccount ?? null;
  }

  goToLoginPage(): Observable<any> {
    if (this._msalGuardConfig.authRequest) {
      return this._msalService.loginRedirect(this._msalGuardConfig.authRequest as RedirectRequest);
    } else {
      return this._msalService.loginRedirect();
    }
  }

  abstract getAuthenticationType(): AuthenticationType;

  private startListeningOnAuthStateChange(): void {
    this._broadcastService.inProgress$
      .pipe(
        takeUntil(this._destroy$),
        tap((status: InteractionStatus) => {
          const account = this.getCurrentAccount();

          if (status == InteractionStatus.None && account != null) {
            this._store.dispatch(new AuthenticationSuccess());
          } else if (status == InteractionStatus.None && account == null) {
            this.goToLoginPage();
          }
        }),
      )
      .subscribe();
  }

  private getTokenExpDate(account: AccountInfo): Date {
    const claims = account.idTokenClaims as any;
    if (!claims.exp) {
      return this._momentService.now().add(1, 'M').toDate();
    }
    const tokenExpDate = new Date(0);
    tokenExpDate.setUTCSeconds(claims.exp);
    return tokenExpDate;
  }
}
