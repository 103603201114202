import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { IContextMenuSectionDefinition } from '../models/context-menu-definition.model';
import {
  IContextMenuButtonState,
  IContextMenuSectionState,
  IContextMenuState,
} from '../models/context-menu-state.model';
import { IIcon } from '@shared/icon/icon.model';

@Component({
  selector: 'app-context-menu-section',
  templateUrl: './context-menu-section.component.html',
  styleUrls: ['./context-menu-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuSectionComponent {
  @Input() sectionDefinition: IContextMenuSectionDefinition;
  @Input() contextMenuState: IContextMenuState;

  @HostBinding('class.hidden')
  get isHidden(): boolean {
    return !this.sectionState?.isVisible;
  }
  get sectionState(): IContextMenuSectionState {
    return this.contextMenuState.sections[this.sectionDefinition.id];
  }
  get isCompactMode(): boolean {
    return this.contextMenuState.displayMode == 'compact';
  }

  constructor() {}

  getIcon(): IIcon {
    return {
      fontIconName: this.sectionState.fontIconName,
      iconRelativePath: this.sectionState.iconRelativePath,
    };
  }

  getButtonState(buttonId: string): IContextMenuButtonState {
    const buttonState = this.contextMenuState.buttons[buttonId];
    buttonState.isDisabled = this.sectionState.isDisabled || buttonState.isDisabled;
    return buttonState;
  }
}
