import { Injectable } from '@angular/core';
import { Router, Params } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, Observable, EMPTY } from 'rxjs';
import { map, mergeMap, switchMap, tap, catchError } from 'rxjs/operators';
import {
  NavigateToEntityCreateForm,
  NavigateToForm,
  RouterActionTypes,
  SaveViewQuery,
  NavigateToEntityView,
  NavigationMode,
  NavigationConfig,
  AddQueryParams,
  NavigateToViewSettings,
  RemoveQueryParams,
  RemoveViewQuery,
  SetQueryParams,
  NavigateToHome,
  NavigateToEntityKanban,
} from './actions';
import { Location } from '@angular/common';
import { NavigationClient, CreateNavigationTargetCommand } from '@core/services/api-clients';
import { ToolsService } from '@core/services/tools-service';
import { RouterActions } from '.';
import { SetUserViewPageSize } from '../../../core/engine-views/store/actions';
import { ErrorsActions } from '@core/errors/store';
import { NavigationActions } from '@core/navigation/store';

@Injectable()
export class RouterEffects {
  navigateToHome$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<NavigateToHome>(RouterActionTypes.NavigateToHome),
        switchMap(() => this._router.navigate([`/`])),
      ),
    { dispatch: false },
  );

  navigateToEntityView$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<NavigateToEntityView>(RouterActionTypes.NavigateToEntityView),
        switchMap((action) =>
          this.processNavigation(
            `/entity/${action.payload.entityId}/view/${action.payload.viewId}`,
            action.payload,
            action.config.baseNavigationPath,
            action.config,
            action.queryParams,
          ),
        ),
      ),
    { dispatch: false },
  );

  navigateToEntityKanban$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<NavigateToEntityKanban>(RouterActionTypes.NavigateToEntityKanban),
        switchMap((action) =>
          this.processNavigation(
            `/entity/${action.payload.entityId}/kanban/${action.payload.kanbanId}`,
            action.payload,
            action.config.baseNavigationPath,
            action.config,
            action.queryParams,
          ),
        ),
      ),
    { dispatch: false },
  );

  saveViewQuery$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveViewQuery>(RouterActionTypes.SaveViewQuery),
      map((x) => x.payload),
      map((payload) => {
        return { viewId: payload.viewId, stateQuery: payload.stateQuery };
      }),
      mergeMap((data) => {
        if (data.stateQuery != null && Object.keys(data.stateQuery).length > 0) {
          return of(
            new RouterActions.AddQueryParams({
              queryParams: {
                [data.viewId]: JSON.stringify(data.stateQuery),
              },
            }),
          );
        } else {
          return of(
            new RouterActions.RemoveQueryParams({
              queryParamsKeys: [data.viewId],
            }),
          );
        }
      }),
    ),
  );

  removeViewQuery$ = createEffect(() =>
    this._actions$.pipe(
      ofType<RemoveViewQuery>(RouterActionTypes.RemoveViewQuery),
      map((x) => x.payload.viewId),
      mergeMap((viewId) =>
        of(
          new RouterActions.RemoveQueryParams({
            queryParamsKeys: [viewId],
          }),
        ),
      ),
    ),
  );

  navigateToForm$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<NavigateToForm>(RouterActionTypes.NavigateToForm),
        switchMap((action) => {
          return this.processNavigation(
            `/entity/${action.payload.recordInfo.entityId}/record/${action.payload.recordInfo.recordId}/form/${action.payload.formId}`,
            {
              entityId: action.payload.recordInfo.entityId,
              formId: action.payload.formId,
              recordId: action.payload.recordInfo.recordId,
            },
            action.config.baseNavigationPath,
            action.config,
            action.queryParams,
            action.onSuccess,
          );
        }),
      ),
    { dispatch: false },
  );

  navigateToEntityCreateForm$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<NavigateToEntityCreateForm>(RouterActionTypes.NavigateToEntityCreateForm),
        switchMap((action) =>
          this.processNavigation(
            `/entity/${action.payload.entityId}/form/${action.payload.formId}`,
            action.payload,
            action.config.baseNavigationPath,
            action.config,
            action.queryParams,
          ),
        ),
      ),
    { dispatch: false },
  );

  addQueryParams$ = createEffect(() =>
    this._actions$.pipe(
      ofType<AddQueryParams>(RouterActionTypes.AddQueryParams),
      map((x) => x.payload.queryParams),
      tap((queryParams) => {
        if (queryParams) {
          const queryParamsAsObject = this.parseQuery(location.search);
          Object.keys(queryParams).forEach((key) => (queryParamsAsObject[key] = queryParams[key]));
          this._location.replaceState(location.pathname, this.getQueryString(queryParamsAsObject));
        }
      }),
      mergeMap((queryParams) =>
        queryParams ? of(new NavigationActions.AddQueryParamsToTopNavigationStackItem({ queryParams })) : EMPTY,
      ),
    ),
  );

  removeQueryParams$ = createEffect(() =>
    this._actions$.pipe(
      ofType<RemoveQueryParams>(RouterActionTypes.RemoveQueryParams),
      map((x) => {
        const queryParamsToRemove = x.payload.queryParamsKeys;
        const queryParamsAsObject = this.parseQuery(location.search);

        // Jeżeli queryParamsToRemove jest nullem lub undefined to usuń wszystkie parametry
        if (queryParamsToRemove) {
          return Object.keys(queryParamsAsObject)
            .filter((e) => !queryParamsToRemove.includes(e))
            .reduce((obj, key) => ({ ...obj, [key]: queryParamsAsObject[key] }), {});
        } else {
          return {};
        }
      }),
      tap((queryParams) => this._location.replaceState(location.pathname, this.getQueryString(queryParams))),
      mergeMap((queryParams) => of(new NavigationActions.SetQueryParamsInTopNavigationStackItem({ queryParams }))),
    ),
  );

  setQueryParams$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SetQueryParams>(RouterActionTypes.SetQueryParams),
      map((x) => x.payload.queryParams),
      mergeMap((queryParams) => [
        new RouterActions.RemoveQueryParams({}),
        new RouterActions.AddQueryParams({ queryParams }),
      ]),
    ),
  );

  navigateToViewSettings$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<NavigateToViewSettings>(RouterActionTypes.NavigateToViewSettings),
        switchMap((action) =>
          this._navigationClient.getUserViewSettingsNavigationInfo(action.payload.viewId).pipe(
            catchError((error) => {
              this._store.dispatch(new ErrorsActions.HandleError({ error }));
              return of(null);
            }),
          ),
        ),
        tap((navInfo) => {
          if (!navInfo) return EMPTY;

          this._toolsService.openFormDialog({
            entityId: navInfo.entityId,
            formId: navInfo.defaultFormId,
            recordId: navInfo.recordId,
            entityName: 'UserViewSettings',
            onSuccess: (recordId: string, record: any) => {
              if (record) {
                this._store.dispatch(
                  new SetUserViewPageSize({
                    pageSize: record.PageSize,
                    viewId: record.ViewId,
                  }),
                );
              }
            },
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _router: Router,
    private _location: Location,
    private _navigationClient: NavigationClient,
    private _toolsService: ToolsService,
  ) {}

  private processNavigation(
    url: string,
    routeData: { [key: string]: string },
    navigationPath: string,
    config: NavigationConfig,
    queryParams?: Params,
    onSuccess?: () => void,
  ): Observable<any> {
    if (config.mode == NavigationMode.SkipLocationChange) {
      this._router
        .navigate(
          [
            url,
            navigationPath
              ? {
                  navigationPath: navigationPath,
                  isFinal: true,
                  navigationTimestamp: Date.now(),
                }
              : { isFinal: true, navigationTimestamp: Date.now() },
          ],
          {
            //queryParamsHandling: "merge",
            skipLocationChange: true,
            queryParams,
          },
        )
        .then(() => this.invokeOnSuccess(onSuccess));

      return EMPTY;
    }

    return this._navigationClient
      .createNavigationTarget(CreateNavigationTargetCommand.fromJS({ routeData: routeData }))
      .pipe(
        tap((token) => {
          if (token) {
            let url =
              config.mode == NavigationMode.AsRoot || (!config.baseNavigationPath && !navigationPath)
                ? config.baseNavigationPath || token
                : config.baseNavigationPath
                ? config.baseNavigationPath + '_' + token
                : navigationPath + '_' + token;

            this._router
              .navigate([url], {
                //queryParamsHandling: "merge",
                queryParams,
              })
              .then(() => this.invokeOnSuccess(onSuccess));
          } else {
            this._router
              .navigate([url], {
                //queryParamsHandling: "merge",
                queryParams,
              })
              .then(() => this.invokeOnSuccess(onSuccess));
          }
        }),
      );
  }

  private parseQuery(queryString: string): any {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      if (pair[0]) {
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }
    }

    return query;
  }

  private getQueryString(queryParams: Params): string {
    const keys = Object.keys(queryParams);
    return keys.length === 0 ? '' : '?' + keys.map((key) => `${key}=${encodeURIComponent(queryParams[key])}`).join('&');
  }

  private invokeOnSuccess(onSuccess: () => void) {
    if (onSuccess) {
      onSuccess();
    }
  }
}
