import { Injectable } from '@angular/core';
import { FormDefinition } from '../components/engine-form/engine-form.model';
import { LodashService } from '@core/services/lodash.service';
import { ControlDto } from '@core/services/api-clients';

@Injectable()
export class FormValueMapper {
  constructor() {}

  getFormValueFromDataItem(dataItem: any, formDefinition: FormDefinition): any {
    const dataItemCopy = LodashService.cloneDeep(dataItem);
    const formValue = {};
    if (dataItemCopy && formDefinition) {
      formDefinition.tabs.forEach((t) => {
        t.controlGroups.forEach((g) => {
          g.controls.forEach((c) => {
            formValue[c.id] = dataItemCopy[c.primaryAttribute];
            // multiselect attribute must be an coma separated string
            if (c.type == 'multiselectoptionset') {
              const controlValue: string = dataItemCopy[c.primaryAttribute] ?? '';
              formValue[c.id] = controlValue.split(', ');
            } else if (c.type == 'inlinerelation') {
              delete formValue[c.id];
            }
          });
        });
      });
    }
    return formValue;
  }

  getDataItemFromFormValue(formValue: any, formDefinition: FormDefinition): any {
    const formValueCopy = LodashService.cloneDeep(formValue);
    const dataItem = {};
    if (formValueCopy && formDefinition) {
      formDefinition.tabs.forEach((t) => {
        t.controlGroups.forEach((g) => {
          g.controls.forEach((c) => {
            this.updateDataItemWithControlValue(dataItem, c, formValueCopy[c.id]);
          });
        });
      });
    }
    return dataItem;
  }

  updateDataItemWithControlValue(dataItem: any, controlDefinition: ControlDto, controlValue: any) {
    if (this.isControlTypeAffectsDataItem(controlDefinition.type)) {
      dataItem[controlDefinition.primaryAttribute] = controlValue;
      // multiselect control value must be an array
      if (controlDefinition.type == 'multiselectoptionset') {
        dataItem[controlDefinition.primaryAttribute] = (controlValue ?? []).join(', ');
      }
    }
  }

  private isControlTypeAffectsDataItem(controlType: string) {
    const dataItemAffectingControlTypes = [
      'yesno',
      'text',
      'textarea',
      'number',
      'datetime',
      'date',
      'time',
      'optionset',
      'multiselectoptionset',
      'lookup',
      'inlinerelation',
      'htmleditor',
      'label',
      'description',
    ];
    return dataItemAffectingControlTypes.some((x) => x === controlType);
  }
}
