import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { switchMap, map, withLatestFrom, catchError, tap, filter, mergeMap } from 'rxjs/operators';
import { FormDto, FormsClient } from '@core/services/api-clients';
import { Store, select } from '@ngrx/store';
import {
  FormActionTypes,
  LoadEntityFormDto,
  LoadEntityFormDtoSuccess,
  LoadCurrentFormDto,
  SaveSubGridSelectedView,
  UpdateEntity,
  DeleteEntities,
  CreateEntity,
  CreateEntityAndNavigateToUpadateForm,
  CreateEntityAndNavigateToCreateForm,
  CreateEntityAndNavigateBack,
  UpdateEntityAndNavigateToCreateForm,
  UpdateEntityAndNavigateBack,
  IPerformOpertionAndNavigate,
  DeleteEntity,
} from './actions';
import { GenericODataServiceProvider } from '@core/engine-odata/services/generic-odata-service-provider.service';
import { NotificationService } from '@core/services/notification.service';
import { FormSelectors } from '.';
import { HandleError } from '@core/errors/store/actions';
import { NavigationActions, NavigationSelectors } from '@core/navigation/store';
import { NavigateByRouteData, NavigateBack } from '@core/navigation/store/actions';
import { getCurrentUser } from '@core/user/store/selectors';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';

@Injectable()
export class FormEffects {
  deleteEntity$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<DeleteEntity>(FormActionTypes.DeleteEntity),
        map((action) => action.payload),
        tap((payload) => {
          const service = this._odataServiceProvider.create(payload.entityName);
          service
            .delete(payload.entityId)
            .pipe(
              tap(() => {
                payload.onSuccess();
                const message = this._translationService.translateInstantly('Form.EntityDeletedSuccessfully');
                this._notificationService.success(message);
              }),
            )
            .subscribe();
        }),
      ),
    { dispatch: false },
  );

  deleteEntities$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<DeleteEntities>(FormActionTypes.DeleteEntities),
        map((action) => action.payload),
        tap((payload) => {
          const service = this._odataServiceProvider.create(payload.entityName);
          const observables = payload.entityIds.map((x) => service.delete(x));
          forkJoin(observables).subscribe(() => {
            payload.onSuccess();
            const message = this._translationService.translateInstantly('Form.EntitiesDeletedSuccessfully');
            this._notificationService.success(message);
          });
        }),
      ),
    { dispatch: false },
  );

  updateEntity$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<UpdateEntity>(FormActionTypes.UpdateEntity),
        map((action) => action.payload),
        tap((payload) => {
          const service = this._odataServiceProvider.create(payload.entityName);
          service
            .update(payload.entityId, payload.entity)
            .pipe(
              catchError((error) => {
                payload.onFailure(error);
                return throwError(error);
              }),
              tap(() => {
                const message = this._translationService.translateInstantly('Form.EntityUpdatedSuccessfully');
                this._notificationService.success(message);
                payload.onSuccess();
              }),
            )
            .subscribe();
        }),
      ),
    { dispatch: false },
  );

  createEntity$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<CreateEntity>(FormActionTypes.CreateEntity),
        map((action) => action.payload),
        switchMap((payload) => {
          const service = this._odataServiceProvider.create(payload.entityName);
          return forkJoin([of(payload), service.create(payload.entity)]).pipe(
            catchError((error) => {
              this._store.dispatch(new HandleError({ error }));
              if (payload.onFailure) payload.onFailure(error);
              return of();
            }),
            tap(([payload, addedEntity]) => {
              const message = this._translationService.translateInstantly('Form.EntityCreatedSuccessfully');
              this._notificationService.success(message);
              payload.onSuccess(addedEntity['Id']);
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  createEntityAndNavigateToUpadateForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateEntityAndNavigateToUpadateForm>(FormActionTypes.CreateEntityAndNavigateToUpadateForm),
      map((action) => action.payload),
      switchMap((payload) => {
        const service = this._odataServiceProvider.create(payload.entityName);
        return service.create(payload.entity).pipe(
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));

            if (payload.onOperationFailure) payload.onOperationFailure(error);

            return of();
          }),
          tap((_) => {
            const message = this._translationService.translateInstantly('Form.EntityCreatedSuccessfully');
            this._notificationService.success(message);
            payload.onOperationSuccess();
          }),
          switchMap((addedEntity) =>
            of(
              new NavigateByRouteData({
                routeData: {
                  entityId: payload.entityId,
                  formId: payload.formId,
                  recordId: addedEntity['Id'],
                },
                popCount: 1,
                onSuccess: payload.onNavigationSuccess,
              }),
            ),
          ),
        );
      }),
    ),
  );

  createEntityAndNavigateToCreateForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateEntityAndNavigateToCreateForm>(FormActionTypes.CreateEntityAndNavigateToCreateForm),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(NavigationSelectors.getArgsQueryParam))),
      switchMap(([payload, args]) => {
        const service = this._odataServiceProvider.create(payload.entityName);
        return service.create(payload.entity).pipe(
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));

            if (payload.onOperationFailure) payload.onOperationFailure(error);

            return of();
          }),
          tap((_) => {
            const message = this._translationService.translateInstantly('Form.EntityCreatedSuccessfully');
            this._notificationService.success(message);
            payload.onOperationSuccess();
          }),
          switchMap((_) => FormEffects.processNavigationToCreateWithArgs(payload, args)),
        );
      }),
    ),
  );

  updateEntityAndNavigateToCreateForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateEntityAndNavigateToCreateForm>(FormActionTypes.UpdateEntityAndNavigateToCreateForm),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(NavigationSelectors.getArgsQueryParam))),
      switchMap(([payload, args]) => {
        const service = this._odataServiceProvider.create(payload.entityName);
        return service.update(payload.recordId, payload.entity).pipe(
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));

            if (payload.onOperationFailure) payload.onOperationFailure(error);

            return of();
          }),
          tap((_) => {
            const message = this._translationService.translateInstantly('Form.EntityUpdatedSuccessfully');
            this._notificationService.success(message);
            payload.onOperationSuccess();
          }),
          switchMap((_) => FormEffects.processNavigationToCreateWithArgs(payload, args)),
        );
      }),
    ),
  );

  createEntityAndNavigateBack$ = createEffect(() =>
    this._actions$.pipe(
      ofType<CreateEntityAndNavigateBack>(FormActionTypes.CreateEntityAndNavigateBack),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(NavigationSelectors.getArgsQueryParam))),
      switchMap(([payload, args]) => {
        let $mappings;
        if (args && args.$mappings) {
          $mappings = args.$mappings;
        }

        const service = this._odataServiceProvider.create(payload.entityName);
        return service.create(payload.entity).pipe(
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));

            if (payload.onOperationFailure) payload.onOperationFailure(error);

            return of();
          }),
          tap((_) => {
            const message = this._translationService.translateInstantly('Form.EntityCreatedSuccessfully');
            this._notificationService.success(message);
            payload.onOperationSuccess();
          }),
          switchMap((dataItem) => FormEffects.processNavigationBackWithMappings(dataItem, $mappings, true)),
        );
      }),
    ),
  );

  updateEntityAndNavigateBack$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateEntityAndNavigateBack>(FormActionTypes.UpdateEntityAndNavigateBack),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(NavigationSelectors.getArgsQueryParam))),
      switchMap(([payload, args]) => {
        const service = this._odataServiceProvider.create(payload.entityName);
        let $mappings;

        if (args && args.$mappings) {
          $mappings = args.$mappings;
        }

        return service.update(payload.recordId, payload.entity).pipe(
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));

            if (payload.onOperationFailure) payload.onOperationFailure(error);

            return of();
          }),
          tap((_) => {
            const message = this._translationService.translateInstantly('Form.EntityUpdatedSuccessfully');
            this._notificationService.success(message);
            payload.onOperationSuccess();
          }),
          switchMap((dataItem) => FormEffects.processNavigationBackWithMappings(dataItem, $mappings)),
        );
      }),
    ),
  );

  loadEntityForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadEntityFormDto>(FormActionTypes.LoadEntityFormDto),
      map((action) => action.payload.formId),
      mergeMap((formId) =>
        this._formsClient.getForm(formId).pipe(
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));
            return of(null);
          }),
        ),
      ),
      mergeMap((entityForm: FormDto) => {
        if (entityForm) return of(new LoadEntityFormDtoSuccess({ form: entityForm }));
        return EMPTY;
      }),
    ),
  );

  loadCurrentForm$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadCurrentFormDto>(FormActionTypes.LoadCurrentFormDto),
      map((action) => action.payload?.forceReload ?? false),
      withLatestFrom(
        this._store.select(NavigationSelectors.getFormIdFromUrl),
        this._store.select(FormSelectors.getFormFromUrl),
      ),
      filter(([forceReload, formId, form]) => !!formId && (!form || forceReload)),
      switchMap(([, formId]) => {
        return of(new LoadEntityFormDto({ formId }));
      }),
    ),
  );

  saveSubGridSelectedView$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SaveSubGridSelectedView>(FormActionTypes.SaveSubGridSelectedView),
        map((x) => x.payload),
        withLatestFrom(this._store.pipe(select(getCurrentUser))),
        tap(([action, currentUser]) => {
          const columnSettingKey = 'SelectedView_' + action.subgridId + currentUser.userId;
          localStorage[columnSettingKey] = action.viewId;
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _formsClient: FormsClient,
    private _odataServiceProvider: GenericODataServiceProvider,
    private _notificationService: NotificationService,
    private _translationService: EngineTranslationService,
    private _store: Store,
  ) { }

  private static getMappings(dataItem, mappings) {
    if (!mappings) {
      return;
    }
    let mappedArgs = {};
    Object.keys(mappings).forEach((key) => (mappedArgs[key] = dataItem[mappings[key]]));
    return mappedArgs;
  }

  private static processNavigationToCreateWithArgs(
    payload: IPerformOpertionAndNavigate,
    args: any,
  ): Observable<NavigateByRouteData> {
    return of(
      new NavigateByRouteData({
        routeData: {
          entityId: payload.entityId,
          formId: payload.formId,
        },
        popCount: 1,
        queryParams: args ? { args: JSON.stringify(args) } : {},
        onSuccess: payload.onNavigationSuccess,
      }),
    );
  }

  private static processNavigationBackWithMappings(
    dataItem: any,
    $mappings: any,
    markAsDirty: boolean = false,
  ): Observable<NavigateBack> {
    if (!$mappings) {
      return of(new NavigationActions.NavigateBack({}));
    }
    const mappings = FormEffects.getMappings(dataItem, $mappings);
    if (markAsDirty) {
      mappings['$markAsDirty'] = markAsDirty;
    }
    return mappings
      ? of(new NavigationActions.NavigateBack({ queryParams: { args: JSON.stringify(mappings) } }))
      : of(new NavigationActions.NavigateBack({}));
  }
}
