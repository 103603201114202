import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Aggregator, AttributeType, KanbanColumnDto, KanbanDto } from '@core/services/api-clients';
import { environment } from '@env';
import { toODataString, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { map, tap } from 'rxjs/operators';
import { KanbanColumnData, KanbanData } from '@app/main-kanban/store/state';
import { ColumnsPageSize } from '@app/main-kanban/store/actions';
import { serializeSelectExpand } from '@shared/utils';
import { IEngineODataState } from 'src/engine-sdk/contract/odata/iodata-state.model';
import { IGNORE_BUSY_INDICATOR } from '@core/layout/components/busy-indicator/busy-indicator.interceptor';

export const KANBAN_SERVICE_TOKEN = new InjectionToken<KanbanDataService>('KANBAN SERVICE TOKEN');

export abstract class KanbanDataService {
  public loading: boolean;

  protected BASE_URL = environment.urls.ODataUrl;

  abstract getKanbanData(
    kanban: KanbanDto,
    columnsPageSize?: ColumnsPageSize,
    searchTerm?: string,
  ): Observable<KanbanData>;

  abstract getKanbanColumnRecords(
    kanban: KanbanDto,
    kanbanColumn: KanbanColumnDto,
    page: number,
    searchTerm?: string,
  ): Observable<any[]>;

  abstract getKanbanColumnAggregators(
    kanban: KanbanDto,
    kanbanColumn: KanbanColumnDto,
    searchTerm?: string,
  ): Observable<{ [aggregatorId: string]: number }>;

  abstract update(kanban: KanbanDto, recordId: string, optionSetValue: string, stackRank: number): Observable<void>;
}

@Injectable({ providedIn: 'root' })
export class DefaultKanbanDataService extends KanbanDataService {
  private _httpContext = new HttpContext();

  constructor(private _http: HttpClient) {
    super();
    this._httpContext.set(IGNORE_BUSY_INDICATOR, true);
  }

  getKanbanData(kanban: KanbanDto, columnsPageSize?: ColumnsPageSize, searchTerm?: string): Observable<KanbanData> {
    const result$ = forkJoin(
      kanban.columns.map((c) =>
        this.getKanbanColumnData(kanban, c, columnsPageSize?.[c.id] ? columnsPageSize?.[c.id] : c.pageSize, searchTerm),
      ),
    );
    return result$.pipe(
      map((r) => {
        return <KanbanData>{
          columns: r.reduce((acc, cur) => ({ ...acc, [cur.columnId]: cur }), {}),
        };
      }),
    );
  }

  getKanbanColumnAggregators(
    kanban: KanbanDto,
    kanbanColumn: KanbanColumnDto,
    searchTerm?: string,
  ): Observable<{ [aggregatorId: string]: number }> {
    if (kanbanColumn.aggregators && kanbanColumn.aggregators.length > 0) {
      let state = kanban.query ? (JSON.parse(kanban.query) as IEngineODataState) : <IEngineODataState>{};

      state.filter = this.getFilter(kanban, kanbanColumn, state.filter, searchTerm);

      let filter = toODataString(state);
      filter = filter.split('=')[1].replace(/'([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'/gi, '$1');
      filter = `filter(${decodeURIComponent(filter)})/`;

      let aggregationsPart = kanbanColumn.aggregators
        .map((x) =>
          x.aggregator != Aggregator.Count
            ? `${x.attribute.name} with ${this.getAggregationType(x.aggregator)} as ${x.id}`
            : `${this.getAggregationType(x.aggregator)} as ${x.id}`,
        )
        .join(',');

      let queryStr = `$apply=${filter}aggregate(${aggregationsPart})`;

      const baseUrl = `${this.BASE_URL}/${kanban.entity.name}`;

      return this._http.get(`${baseUrl}?${queryStr}`, { context: this._httpContext }).pipe(
        map((response: any) => response.value[0]),
        tap(() => (this.loading = false)),
        map((result: { [aggregatorId: string]: number }) => result || null),
      );
    }

    return of({});
  }

  update(kanban: KanbanDto, recordId: string, optionSetValue: string, stackRank: number): Observable<any> {
    return this._http.patch(
      `${this.BASE_URL}/${kanban.entity.name}(${recordId})`,
      {
        Id: recordId,
        [kanban.optionSetAttribute.name]: optionSetValue,
        [kanban.stackRankAttribute.name]: stackRank,
      },
      { context: this._httpContext },
    );
  }

  public getKanbanColumnData(
    kanban: KanbanDto,
    kanbanColumn: KanbanColumnDto,
    pageSize: number,
    searchTerm?: string,
  ): Observable<KanbanColumnData> {
    return forkJoin([
      this.getKanbanColumnRecords(kanban, kanbanColumn, pageSize, searchTerm),
      this.getKanbanColumnAggregators(kanban, kanbanColumn, searchTerm),
    ]).pipe(
      map(
        ([records, aggregators]) =>
          <KanbanColumnData>{
            records: records,
            aggregators: aggregators,
            columnId: kanbanColumn.id,
            pageSize: pageSize,
          },
      ),
    );
  }

  getKanbanColumnRecords(
    kanban: KanbanDto,
    kanbanColumn: KanbanColumnDto,
    pageSize: number,
    searchTerm?: string,
  ): Observable<any[]> {
    let state = kanban.query ? (JSON.parse(kanban.query) as IEngineODataState) : <IEngineODataState>{};

    state.take = pageSize + 1;
    state.filter = this.getFilter(kanban, kanbanColumn, state.filter, searchTerm);
    state.sort = [
      {
        field: kanban.stackRankAttribute.name,
        dir: 'asc',
      },
    ];

    let query = toODataString(state);
    let selectExpandPart = serializeSelectExpand(kanban.kanbanAttributes);
    if (selectExpandPart) query += `&${selectExpandPart}`;

    const baseUrl = `${this.BASE_URL}/${kanban.entity.name}`;

    this.loading = true;
    return this._http.get(`${baseUrl}?${query}`, { context: this._httpContext }).pipe(
      map((response) => <any[]>response['value']),
      tap(() => (this.loading = false)),
    );
  }

  private getAggregationType(type: Aggregator): string {
    switch (type) {
      case Aggregator.Average:
        return 'average';
      case Aggregator.Count:
        return '$count';
      case Aggregator.Max:
        return 'max';
      case Aggregator.Min:
        return 'min';
      case Aggregator.Sum:
        return 'sum';
      case Aggregator.CountDistinct:
        return 'countdistinct';
    }
  }

  private getFilter(
    kanban: KanbanDto,
    kanbanColumn: KanbanColumnDto,
    currentFilter: CompositeFilterDescriptor,
    searchTerm: string,
  ): CompositeFilterDescriptor {
    let result = <CompositeFilterDescriptor>{
      logic: 'and',
      filters: [
        <FilterDescriptor>{
          operator: 'eq',
          field: kanban.optionSetAttribute.name,
          value: kanbanColumn.optionSetValue.name,
        },
      ],
    };

    if (currentFilter && currentFilter.filters.length > 0) {
      result.filters.push(result);
    }

    if (searchTerm) {
      result = <CompositeFilterDescriptor>{
        logic: 'and',
        filters: [result, this.getSearchTermFilter(kanban, searchTerm)],
      };
    }

    return result;
  }

  private getSearchTermFilter(kanban: KanbanDto, searchTerm: string): CompositeFilterDescriptor {
    const filter = <CompositeFilterDescriptor>{
      logic: 'or',
      filters: [],
    };

    for (const control of kanban.controls) {
      if (control.attribute.type == AttributeType.Text) {
        filter.filters.push(<FilterDescriptor>{
          field: control.attributePath,
          operator: 'contains',
          value: searchTerm,
          ignoreCase: true,
        });
      }
    }

    return filter;
  }
}
