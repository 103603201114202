import { ChangeDetectionStrategy, Component, forwardRef, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseFormControlDirective } from '../../directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';

@Component({
  selector: 'app-number-control',
  templateUrl: './number-control.component.html',
  styleUrls: ['./number-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: NumberControlComponent,
    },
  ],
})
export class NumberControlComponent extends BaseFormControlDirective {
  private _minValue: number = null;
  private _maxValue: number = null;

  @Input() textAlignStyle: any;

  @Input() set maxValue(value: number | undefined | null) {
    if (Number.isInteger(value)) {
      this._maxValue = value;
      this.addValidator('max', Validators.max(value));
    } else {
      this._maxValue = null;
      this.removeValidator('max');
    }
  }

  @Input() set minValue(value: number | undefined | null) {
    if (Number.isInteger(value)) {
      this._minValue = value;
      this.addValidator('min', Validators.min(value));
    } else {
      this._minValue = value;
      this.removeValidator('min');
    }
  }

  get maxValue() {
    return this._maxValue;
  }

  get minValue() {
    return this._minValue;
  }

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }
}
