<div *ngIf="formControl">
  <mat-form-field class="control-input" [ngStyle]="textAlignStyle">
    <mat-select [formControl]="formControl" [id]="id" [attr.name]="name" [placeholder]="label">
      <mat-option *ngFor="let option of options" [value]="option.value" (blur)="onBlur()">
        {{ getLabel(option) }}
      </mat-option>
    </mat-select>
    <button
      type="button"
      tabindex="-1"
      [disabled]="formControl.disabled"
      class="autocomplete-action-button visible-on-edit-mode"
      matSuffix
      mat-icon-button>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </mat-form-field>
  <app-control-error [control]="formControl" [offset]="-16"></app-control-error>
</div>
