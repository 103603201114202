import { Injectable } from '@angular/core';
import { EngineCultureService } from '@core/engine-culture/services/culture.service';
import { DateTimeAdapter, NativeDateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import moment from 'moment';
import { EngineDateParser } from '../../engine-culture/services/date-parser.service';

@Injectable()
export class OwlDateAdapter extends DateTimeAdapter<any> {
  constructor(
    private _timeAdapter: NativeDateTimeAdapter,
    private _dateParser: EngineDateParser,
    private _cultureService: EngineCultureService
  ) {
    super();
  }

  getYear(date: any): number {
    return this._timeAdapter.getYear(date);
  }
  getMonth(date: any): number {
    return this._timeAdapter.getMonth(date);
  }
  getDay(date: any): number {
    return this._timeAdapter.getDay(date);
  }
  getDate(date: any): number {
    return this._timeAdapter.getDate(date);
  }
  getHours(date: any): number {
    return this._timeAdapter.getHours(date);
  }
  getMinutes(date: any): number {
    return this._timeAdapter.getMinutes(date);
  }
  getSeconds(date: any): number {
    return this._timeAdapter.getSeconds(date);
  }
  getTime(date: any): number {
    return this._timeAdapter.getTime(date);
  }
  getNumDaysInMonth(date: any): number {
    return this._timeAdapter.getNumDaysInMonth(date);
  }
  differenceInCalendarDays(dateLeft: any, dateRight: any): number {
    return this._timeAdapter.differenceInCalendarDays(dateLeft, dateRight);
  }
  getYearName(date: any): string {
    return this._timeAdapter.getYearName(date);
  }
  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this._timeAdapter.getMonthNames(style);
  }
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this._timeAdapter.getDayOfWeekNames(style);
  }
  getDateNames(): string[] {
    return this._timeAdapter.getDateNames();
  }
  toIso8601(date: any): string {
    return this._timeAdapter.toIso8601(date);
  }
  isEqual(dateLeft: any, dateRight: any): boolean {
    return this._timeAdapter.isEqual(dateLeft, dateRight);
  }
  isSameDay(dateLeft: any, dateRight: any): boolean {
    return this._timeAdapter.isSameDay(dateLeft, dateRight);
  }
  isValid(date: any): boolean {
    return this._timeAdapter.isValid(date);
  }
  invalid() {
    return this._timeAdapter.invalid();
  }
  isDateInstance(obj: any): boolean {
    return this._timeAdapter.isDateInstance(obj);
  }
  addCalendarYears(date: any, amount: number) {
    return this._timeAdapter.addCalendarYears(date, amount);
  }
  addCalendarMonths(date: any, amount: number) {
    return this._timeAdapter.addCalendarMonths(date, amount);
  }
  addCalendarDays(date: any, amount: number) {
    return this._timeAdapter.addCalendarDays(date, amount);
  }
  setHours(date: any, amount: number) {
    return this._timeAdapter.setHours(date, amount);
  }
  setMinutes(date: any, amount: number) {
    return this._timeAdapter.setMinutes(date, amount);
  }
  setSeconds(date: any, amount: number) {
    return this._timeAdapter.setSeconds(date, amount);
  }
  createDate(year: number, month: number, date: number, hours?: number, minutes?: number, seconds?: number): any {
    // when someone pick a date we want it to be UTC midnight despite user timezone
    return new Date(Date.UTC(year, month, date, hours ?? 0, minutes ?? 0, seconds ?? 0));
  }
  clone(date: any) {
    return this._timeAdapter.clone(date);
  }
  now() {
    return this._timeAdapter.now();
  }
  format(date: any, displayFormat: any): string {
    return this._dateParser.toString(date, this._cultureService.cultureSettings.dateTimeParsingPattern);
  }
  parse(value: any, parseFormat: any) {
    if (value) {
      const result = moment(value, this._cultureService.cultureSettings.dateShortPattern.toUpperCase(), true)

      if (result.isValid()) {
        return result.toDate();
      }
    }

    return undefined;
  }
}
