import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebResourceUriPipe } from './pipes/relative-to-webresource-base64uri.pipe';
import { SafeUrlPipe } from './pipes/dom-sanitizer.pipe';
import { DoubleClickDirective } from './directives/double-click.directive';
import { ResizableInputDirective } from './directives/resizable-input.directive';
import { ResizeDirective } from './directives/resize.directive';
import { ContainerWidthDirective } from './directives/container-width.directive';

@NgModule({
  declarations: [
    WebResourceUriPipe,
    SafeUrlPipe,
    ResizeDirective,
    ResizableInputDirective,
    DoubleClickDirective,
    ContainerWidthDirective,
  ],
  imports: [CommonModule],
  exports: [
    WebResourceUriPipe,
    SafeUrlPipe,
    ResizeDirective,
    ResizableInputDirective,
    DoubleClickDirective,
    ContainerWidthDirective,
  ],
})
export class UtilsModule {}
