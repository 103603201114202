import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule as KendoGridModule } from '@progress/kendo-angular-grid';
import { HyperlinkModule } from '@shared/hyperlink/hyperlink.module';
import { SharedModule } from '../shared.module';
import { AggregationDropdownComponent } from './components/aggregation-dropdown/aggregation-dropdown.component';
import { OptionSetFilterComponent } from './components/custom-filters/option-set-filter/option-set-filter.component';
import { GridColumnCellComponent } from './components/grid-column-cell/grid-column-cell.component';
import { GridPageSizeSelectorComponent } from './components/grid-page-size-selector/grid-page-size-selector.component';
import { GridDisplayRowsDirective } from './directives/grid-display-rows.directive';
import { GridComponent } from './grid.component';
import { AggregationLabelPipe } from './pipes/aggregation-label.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { IconPipe } from './pipes/icon.pipe';
import { LookupPipe } from './pipes/lookup.pipe';
import { OptionSetPipe } from './pipes/optionSet.pipe';
import { TimePipe } from './pipes/time.pipe';
import { RatingOptionsPipe } from './pipes/rating-options.pipe';
import { OptionSetValuePipe } from './pipes/option-set-value.pipe';
import { OptionSetColorPipe } from './pipes/option-set-color.pipe';
import { IconUrlPipe } from './pipes/icon-url.pipe';
import { IconTitlePipe } from './pipes/icon-title.pipe';
import { CombinedTextsPipe } from './pipes/combined-texts.pipe';
import { HyperlinkPipe } from './pipes/hyperlink.pipe';
import { TimeFilterComponent } from './components/custom-filters/time-filters/time-filter.component';
import { TimeFilterMenuInputComponent } from './components/custom-filters/time-filters/time-filter-menu-input/time-filter-menu-input.component';
import { VisibleColumnsPipe } from './pipes/visible-columns.pipe';
import { CombinedTextsFilterComponent } from './components/custom-filters/combined-texts-filter/combined-texts-filter.component';
import { EngineProcessesModule } from '../../core/engine-processes/engine-processes.module';

@NgModule({
  declarations: [
    GridComponent,
    GridColumnCellComponent,
    GridPageSizeSelectorComponent,
    AggregationDropdownComponent,
    AggregationLabelPipe,
    OptionSetFilterComponent,
    DateTimePipe,
    OptionSetPipe,
    OptionSetColorPipe,
    GridDisplayRowsDirective,
    TimePipe,
    RatingOptionsPipe,
    OptionSetValuePipe,
    IconPipe,
    IconUrlPipe,
    IconTitlePipe,
    CombinedTextsPipe,
    LookupPipe,
    HyperlinkPipe,
    TimeFilterMenuInputComponent,
    TimeFilterComponent,
    VisibleColumnsPipe,
    CombinedTextsFilterComponent,
  ],
  exports: [GridComponent, GridDisplayRowsDirective],
  imports: [
    CommonModule,
    FormsModule,
    KendoGridModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSelectModule,
    TranslateModule,
    DropDownsModule,
    HyperlinkModule,
    SharedModule,
    EngineProcessesModule,
  ],
})
export class GridModule {}
