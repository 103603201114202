<div class="duration-input-container"
     [ngClass]="{ 'duration-disabled': disabled }"
     (focusin)="onFocusIn()"
     (focusout)="onFocusOut($event)"
     tabindex="-1">
  <div class="duration-group"
       role="group"
       [formGroup]="durationGroup">
    <input *ngIf="isVisible('days')"
           class="duration-input-element"
           formControlName="days"
           (blur)="blur.emit()"
           size="2"
           duration />
    <span *ngIf="isVisible('days') && isVisible('hours')" class="duration-input-spacer">.</span>
    <input *ngIf="isVisible('hours')"
           class="duration-input-element"
           formControlName="hours"
           (blur)="blur.emit()"
           size="2"
           duration />
    <span *ngIf="isVisible('hours') && isVisible('minutes')" class="duration-input-spacer">:</span>
    <input *ngIf="isVisible('minutes')"
           class="duration-input-element"
           formControlName="minutes"
           (blur)="blur.emit()"
           size="2"
           time />
    <span *ngIf="isVisible('minutes') && isVisible('seconds')" class="duration-input-spacer">:</span>
    <input *ngIf="isVisible('seconds')"
           class="duration-input-element"
           formControlName="seconds"
           (blur)="blur.emit()"
           size="2"
           duration />
  </div>
  <div class="duration-icon">
    <mat-icon (click)="clear()">clear</mat-icon>
    <div class="arrow-icons">
      <mat-icon (click)="add()">arrow_drop_up</mat-icon>
      <mat-icon (click)="subtract()">arrow_drop_down</mat-icon>
    </div>
  </div>
</div>
