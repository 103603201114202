import { NotificationGroup, Notification } from 'src/engine-sdk';
import { NotificationsActions, NotificationsActionTypes } from './actions';
import { initialNotificationsState, INotificationsState } from './state';

export function notificationsReducer(
  state = initialNotificationsState,
  action: NotificationsActions,
): INotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.AddNotifications: {
      let hasNewNotifications = false;
      let groups = { ...state.notificationGroups };
      action.payload.notificationGroups.forEach((group) => {
        const key = getNotificationGroupKey(group);
        const currentNotifications = groups[key]?.notifications ?? [];
        const newNotifications = filterNotifications(currentNotifications, group.notifications);

        if (newNotifications.length) {
          groups[key] = { ...group, notifications: [].concat(currentNotifications, newNotifications) };
          // groups[key].notifications = [].concat(currentNotifications, newNotifications);
          hasNewNotifications = true;
        }
      });

      if (hasNewNotifications) {
        return {
          ...state,
          notificationGroups: groups,
        };
      } else {
        return state;
      }
    }
    case NotificationsActionTypes.SetNotifications: {
      let groups = {};

      action.payload.notificationGroups.forEach((group) => {
        const key = getNotificationGroupKey(group);

        groups[key] = { ...groups[key], ...group };
        (<NotificationGroup>groups[key]).notifications = group.notifications;
      });

      return {
        ...state,
        notificationGroups: groups,
      };
    }
    case NotificationsActionTypes.RemoveAllNotifications: {
      return {
        ...state,
        notificationGroups: {},
      };
    }
    case NotificationsActionTypes.RemoveNotificationGroup: {
      const group = action.payload.notificationGroup;
      const key = getNotificationGroupKey(group);

      if (!state.notificationGroups[key]) return state;

      const { [key]: _, ...filteredGroups } = state.notificationGroups;

      return {
        ...state,
        notificationGroups: filteredGroups,
      };
    }
    case NotificationsActionTypes.RemoveNotifications: {
      let groups = { ...state.notificationGroups };
      let group = action.payload.notificationGroup;
      const key = getNotificationGroupKey(group);

      if (groups[key]) {
        let notifications = [...groups[key].notifications];
        group.notifications.forEach((notificationToRemove) => {
          let index = notifications.findIndex(
            (x) => x.message === notificationToRemove.message && x.type === notificationToRemove.type,
          );
          if (index >= 0) {
            notifications.splice(index, 1);
          }
        });

        if (notifications.length) {
          groups = {
            ...groups,
            [key]: {
              ...groups[key],
              notifications: notifications,
            },
          };
        } else {
          const { [key]: _, ...filteredGroups } = groups;
          groups = filteredGroups;
        }

        return {
          ...state,
          notificationGroups: groups,
        };
      }

      return state;
    }
    default:
      return state;
  }
}

function getNotificationGroupKey(group: NotificationGroup) {
  return group.entityName ? `${group.name}_${group.scope}_${group.entityName}` : `${group.name}_${group.scope}`;
}

function filterNotifications(baseArray: Notification[], newArray: Notification[]) {
  return newArray.filter(
    (notification) => !baseArray.some((n) => n.message === notification.message && n.type === notification.type),
  );
}
