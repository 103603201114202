<div *ngIf="formControl">
  <mat-form-field class="control-input" [ngStyle]="textAlignStyle">
    <input class="autocomplete-value-input" [formControl]="formControl" [id]="id" [name]="name" />
    <input
      class="autocomplete-label-input"
      [formControl]="labelFormControl"
      (blur)="onBlur()"
      (keyup)="onFilterChange($event)"
      (focusin)="onFocusIn($event)"
      matInput
      [placeholder]="label"
      [matAutocomplete]="auto" />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      (optionSelected)="onOptionSelected($event)">
      <mat-option *ngFor="let option of options" [value]="option">
        {{ option.label }}
      </mat-option>
      <mat-option *ngFor="let actionOption of actionOptions" class="action-option" [value]="{ value: actionOption.id }">
        {{ actionOption.label }}
      </mat-option>
    </mat-autocomplete>
    <button
      type="button"
      tabindex="-1"
      class="autocomplete-action-button visible-on-edit-mode"
      *ngIf="!!formControl.value"
      matSuffix
      mat-icon-button
      (click)="onClearClick()">
      <mat-icon>close</mat-icon>
    </button>
    <button
      type="button"
      tabindex="-1"
      [disabled]="formControl.disabled"
      class="autocomplete-action-button visible-on-edit-mode"
      matSuffix
      mat-icon-button>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <button
      type="button"
      tabindex="-1"
      class="autocomplete-action-button visible-on-edit-mode"
      *ngIf="isOpenButtonVisible && this.formControl.value"
      matSuffix
      mat-icon-button
      (click)="onEditClick($event)">
      <mat-icon>visibility</mat-icon>
    </button>
    <button
      type="button"
      tabindex="-1"
      class="autocomplete-action-button visible-on-edit-mode"
      *ngIf="isUpdateButtonVisible && this.formControl.value"
      matSuffix
      mat-icon-button
      (click)="onEditClick($event)">
      <mat-icon>edit</mat-icon>
    </button>
    <button
      type="button"
      tabindex="-1"
      class="autocomplete-action-button visible-on-edit-mode"
      *ngIf="isCreateButtonVisible && !this.formControl.value"
      matSuffix
      mat-icon-button
      (click)="onAddClick()">
      <mat-icon>add</mat-icon>
    </button>
  </mat-form-field>

  <app-control-error [control]="formControl" [offset]="-16"></app-control-error>
</div>
