import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  Output,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/reactive-controls/directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '@shared/reactive-controls/models/iculture-service.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import {
  IAutocompleteGroup,
  IGroupedAutocompleteActionOption,
  IGroupedAutocompleteOption,
  IGroupedAutocompleteOptions,
} from './grouped-autocomplete-control.model';

@Component({
  selector: 'app-grouped-autocomplete-control',
  templateUrl: './grouped-autocomplete-control.component.html',
  styleUrls: ['./grouped-autocomplete-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GroupedAutocompleteControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: GroupedAutocompleteControlComponent,
    },
  ],
})
export class GroupedAutocompleteControlComponent extends BaseFormControlDirective implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _debouncer$: Subject<string> = new Subject<string>();
  private _groupedOptions: IGroupedAutocompleteOptions;
  private _groupedValuesToLabels: { [groupId: string]: { [value: string]: string } } = {};
  private _groups: IAutocompleteGroup[];

  @Input() textAlignStyle: any;

  @Input()
  set groups(v: IAutocompleteGroup[]) {
    this._groups = v;
    if (this.groups.length) {
      this.selectedGroupId = this.groups[0].id;
    }
  }

  @Input()
  set groupedOptions(v: IGroupedAutocompleteOptions) {
    this._groupedOptions = v;
    Object.keys(v).forEach((groupId) =>
      v[groupId].forEach((option) => {
        this._groupedValuesToLabels[groupId] = {};
        this._groupedValuesToLabels[groupId][option.value] = option.label;
      }),
    );
    if (!!Object.keys(v).length && !!this.formControl.value && this.labelFormControl.value instanceof Object && !this.labelFormControl.value.label) {
      this.syncLabelInputWithValueInput();
    }
  }
  @Input() override set isRequired(v: boolean) {
    super.isRequired = v;
    this.setLabelInputValidators();
  }
  @Input() actionOptions: IGroupedAutocompleteActionOption[] = [];
  @Input() isCreateButtonVisible: boolean;
  @Input() isOpenButtonVisible: boolean;
  @Input() isUpdateButtonVisible: boolean;

  @Output() changeValue = new EventEmitter<IGroupedAutocompleteOption>();
  @Output() groupChanged = new EventEmitter<string>();
  @Output() filterChange = new EventEmitter<string>();
  @Output() editClick = new EventEmitter<string>();
  @Output() addClick = new EventEmitter<string>();

  selectedGroupId: string | null = null;
  labelFormControl: UntypedFormControl;
  get groupedOptions(): IGroupedAutocompleteOptions {
    return this._groupedOptions;
  }
  get selectedGroupOptions(): IGroupedAutocompleteOption[] {
    return this.selectedGroupId ? this._groupedOptions[this.selectedGroupId] : [];
  }
  get groups(): IAutocompleteGroup[] {
    return this._groups;
  }
  override get isRequired() {
    return super.isRequired;
  }
  get isEmpty(): boolean {
    return !!this.formControl.value && Object.values(this.formControl.value).every((v) => !v);
  }

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
    this.labelFormControl = new UntypedFormControl({
      value: !!this.formControl.value ? { value: this.formControl.value, label: '' } : undefined,
      disabled: this.formControl.disabled,
    });

    this._debouncer$.pipe(
      takeUntil(this._destroy$),
      debounceTime(250),
      distinctUntilChanged(),
      tap(searchTerm => this.filterChange.emit(searchTerm))
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  onSelectedGroupChanged(selectedGroupId: string): void {
    this.selectedGroupId = selectedGroupId;
    this.groupChanged.emit(selectedGroupId);
  }

  setGroups(groups: IAutocompleteGroup[]): void {
    this.groups = groups;
  }

  setSelectedGroup(groupId: string): void {
    this.selectedGroupId = groupId;
    this._changeDetector.markForCheck();
  }

  setCreateButtonVisibility(visibility: boolean) {
    this.isCreateButtonVisible = visibility;
    this._changeDetector.markForCheck();
  }

  setOpenButtonVisibility(visibility: boolean) {
    this.isOpenButtonVisible = visibility;
    this._changeDetector.markForCheck();
  }

  setUpdateButtonVisibility(visibility: boolean) {
    this.isUpdateButtonVisible = visibility;
    this._changeDetector.markForCheck();
  }

  setActionOptions(actions: IGroupedAutocompleteActionOption[]) {
    this.actionOptions = actions;
    this._changeDetector.markForCheck();
  }

  override writeValue(value: any): void {
    if (this.formControl.value !== value) {
      this.formControl.setValue(value);
    }

    this.syncLabelInputWithValueInput();
  }

  override setDisabledState(isDisabled: boolean): void {
    if (isDisabled == this.formControl.disabled) return;
    isDisabled ? this.formControl.disable() : this.formControl.enable();
    isDisabled ? this.labelFormControl.disable() : this.labelFormControl.enable();
  }

  override onBlur(): void {
    const shouldCleanUpLabel = !this.formControl.value && this.labelFormControl.value != '';
    if (shouldCleanUpLabel) {
      this.labelFormControl.patchValue('');
      this.filterChange.emit('');
    }

    super.onBlur();
  }

  onFocusIn(event: Event): void {
    const target: HTMLInputElement = <any>event.target;
    this.filterChange.emit(target.value);
  }

  onFilterChange(event: Event): void {
    const target: HTMLInputElement = <any>event.target;
    this._debouncer$.next(target.value);
  }

  onOptionSelected(event: any): void {
    const value = event?.option?.value?.value;
    const actionOption = this.tryGetActionOptionByValue(value);
    if (!!actionOption) {
      this.syncLabelInputWithValueInput();
      actionOption.handler(this.selectedGroupId);
      return;
    }

    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
    const newValue = {};
    newValue[this.selectedGroupId] = value ?? null;
    this.formControl.patchValue(newValue);
  }

  onClearClick(): void {
    if (this.formControl.disabled) return;
    const emptyValue = {};
    this.groups.forEach((g) => (emptyValue[g.id] = null));

    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
    this.formControl.patchValue(emptyValue);
    this.labelFormControl.patchValue(null);
  }

  onEditClick(event: MouseEvent): void {
    this.editClick.emit(this.selectedGroupId);
  }

  onAddClick(): void {
    if (this.formControl.disabled) return;
    this.addClick.emit(this.selectedGroupId);
  }

  displayFn(option: IGroupedAutocompleteOption): string {
    if (!option) return '';
    return option.label;
  }

  private tryGetActionOptionByValue(optionValue: any): IGroupedAutocompleteActionOption {
    return this.actionOptions.find((b) => b.id == optionValue) ?? undefined;
  }

  private syncLabelInputWithValueInput() {
    const value = !!this.formControl.value
      ? {
        value: this.formControl.value,
        label: this.getValueLabel(this.formControl.value)
      }
      : undefined;

    if (this.labelFormControl.value != value) {
      this.labelFormControl.patchValue(value);
    }
  }

  private setLabelInputValidators(): void {
    if (!this.labelFormControl) return;
    this.labelFormControl.setValidators(Object.values(this._validators).filter((v) => !!v));
    this.labelFormControl.updateValueAndValidity({ emitEvent: true });
  }

  private getValueLabel(value: any) {
    if (!value) return '';
    return this.groups
      .map((g) => this.getOptionLabel(this.formControl.value[g.id], g.id))
      .filter((x) => !!x)
      .join(',');
  }

  private getOptionLabel(optionValue: string, groupId: string) {
    if (
      !optionValue ||
      !groupId ||
      !this._groupedValuesToLabels ||
      !Object.keys(this._groupedValuesToLabels).length ||
      !this._groupedValuesToLabels[groupId]
    )
      return null;

    return this._groupedValuesToLabels[groupId][optionValue] ?? null;
  }
}
